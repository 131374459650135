// 直接用按需导出，可以导出多个
// 但是按需导出，导入时必须 import { getToken } from '模块名导入'

// 获取token
export const getToken = (KEY) => {
  return localStorage.getItem(KEY)
}

// 设置token
export const setToken = (KEY, newToken) => {
  localStorage.setItem(KEY, newToken)
}

// 删除token
export const delToken = (KEY) => {
  localStorage.removeItem(KEY)
}

// 获取用户信息
export const getUserInfo = (KEY) => {
  return localStorage.getItem(KEY)
}

//设置用户信息
export const setUserInfo = (KEY, newInfo) => {
  localStorage.setItem(KEY, JSON.stringify(newInfo))
}

//删除用户信息
export const delUserInfo = (KEY) => {
  localStorage.removeItem(KEY)
}

