<template>
  <div class="my-navbar">
    <NavBar @click-left="onClickLeft" :placeholder="placeholder">
      <template #title>
        <div>
          {{ title }}
        </div>
      </template>
      <template #left>
        <Icon name="arrow-left" color="#000" />
      </template>
      <template #right>
        <slot name="right">
          <h3></h3>
        </slot>
      </template>
    </NavBar>
  </div>
</template>

<script>
import { NavBar,Icon } from "vant";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NavBar,
    Icon,
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
  },
};
</script>

<style lang='scss' scoped>
:deep .van-nav-bar {
  background-color: transparent !important;
}
</style>