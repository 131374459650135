import request from "@/utils/request";


// 发送短信验证码
export const smsCode = (params) => {
  return request({
    url: "/mobile",
    method: "get",
    params,
  })
}



// 登录
export const smsCodeLogin = (params) => {
  return request({
    url: "/auth/token",
    method: "get",
    params,
  })
}

// 注册
export const register = (data) => {
  return request({
    url: "/auth/register",
    method: "post",
    data,
  })
}

// 微信授权登录
export const wechatAuth = (params) => {
  return request({
    url: "/auth/register/wechatAuth",
    method: "get",
    params,
  })
}

// 获取微信js配置
export const wechatJsConfig = (params) => {
  return request({
    url: "/wechat/jsConfig",
    method: "get",
    params,
  })
}


// 获取用户信息
export const getUser = (params) => {
  return request({
    url: "/auth/info",
    method: "get",
    params,
  })
}

// 修改个人信息
export const editUser = (data) => {
  return request({
    url: "/auth/info",
    method: "put",
    data,
  })
}


// 刊物列表
export const productList = (params) => {
  return request({
    url: "/product/list",
    method: "get",
    params,
  })
}

// 刊物详情
export const productDetail = (params) => {
  return request({
    url: "/product",
    method: "get",
    params,
  })
}


// 获取配置
export const getConfig = (params) => {
  return request({
      url: "/config",
      method: "get",
      params,
  })
}

// 上传文件
export const uploadFile = (data, headers) => {
  return request({
      url: "/upload",
      method: "post",
      data,
      headers
  })
}


// 获取所有年级
export const getAllGrade = (params) => {
  return request({
      url: "/grade/all",
      method: "get",
      params,
  })
}

// 获取所有班级
export const getAllClass = (params) => {
  return request({
      url: "/classes/all",
      method: "get",
      params,
  })
}

// 获取所有学校
export const getAllSchool = (params) => {
  return request({
      url: "/school/all",
      method: "get",
      params,
  })
}

// 获取学校详情
export const getSchoolDetail = (params) => {
  return request({
      url: "/school/info",
      method: "get",
      params,
  })
}


// 订单列表
export const orderList = (params) => {
  return request({
      url: "/transaction/list",
      method: "get",
      params,
  })
}

// 订单详情
export const orderInfo = (params) => {
  return request({
      url: "/transaction",
      method: "get",
      params,
  })
}

// 创建订单
export const createOrder = (data) => {
  return request({
      url: "/transaction",
      method: "post",
      data,
  })
}

// 生成支付订单
export const createPayOrder = (data) => {
  return request({
      url: "/transaction/pay",
      method: "post",
      data,
  })
}

// 取消订单
export const cancelOrder = (data) => {
  return request({
      url: "/transaction/cancel",
      method: "put",
      data,
  })
}

// 修改订单订购信息
export const editOrderUser = (data) => {
  return request({
      url: "/transaction/changeUserInfo",
      method: "put",
      data,
  })
}


// 确认收货
export const confirmOrder = (data) => {
  return request({
      url: "/transaction/confirm",
      method: "put",
      data,
  })
}

// 申请退款
export const refundOrder = (data) => {
  return request({
      url: "/transactionAfterSale",
      method: "post",
      data,
  })
}

// 取消退款申请
export const cancelRefundOrder = (data) => {
  return request({
      url: "/transactionAfterSale/cancel",
      method: "put",
      data,
  })
}


// 获取地址库
export const getAreaTree = (params) => {
  return request({
      url: "/area/tree",
      method: "get",
      params,
  })
}










