<template>
  <div id="root" class="flex-column">
    <router-view/>
    <div style="position:absolute;top: 50%;left: 50%;transform: translate(-50%);">
      <Loading v-show="isShow"
      size="24px"
      color="#1989fa"
      vertical />
    </div>
    <div v-show="isShow" class="my-loading-mask" style="position: fixed;width: 100vw; height: 100vh;background-color: #ffffff60;top: 0;left: 0;z-index: 999;"></div>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Loading } from "vant";

window.onload = function () {
  // 禁用双指放大
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  // 禁用双击放大
  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    function (event) {
      const now = new Date().getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

export default {
  components: {
    Loading
  },
  computed: {
    ...mapState(['isShow'])
  }
}
</script>
<style lang="scss">
@import url('./style/common.scss');
#root {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.van-loading__spinner {
  width: 48px !important;
  height: 48px !important;
}
</style>
