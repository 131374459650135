<template>
  <div>
    <Popup
      :value="show"
      @click-overlay="close"
      position="bottom"
      :style="{ maxHeight: height }"
      class="mypop flex-column no-scroll"
    >
      <div class="flex-between title-box">
        <slot name="title">
          <div>{{ title }}</div>
        </slot>
        <img
          src="@/assets/img/pic3.png"
          alt=""
          class="close-icon"
          @click="close"
        />
      </div>
      <div class="flex1 flex-column no-scroll">
        <slot />
      </div>
    </Popup>
  </div>
</template>

<script>
import { Popup } from "vant";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "45%",
    },
  },
  components: {
    Popup,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang='scss' scoped>
:deep .van-popup {
  border-radius: 16px 16px 0px 0px;
  padding: 26px;
  box-sizing: border-box;
}
.close-icon {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 4px;
  right: 0;
}
.title-box {
  font-family: PingFang SC Bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  color: #181818;
  margin-bottom: 16px;
  position: relative;
}
</style>