import Vue from 'vue'
import Vuex from 'vuex'
import user from './user/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShow: false,
  },
  mutations: {
    // 展示loading的方法
    showLoading(state) {
      state.isShow = true
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.isShow = false
    },
  },
  modules: {
    user
  }
})
