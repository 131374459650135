<template>
  <div class="flex-column flex1 default">
    <img :src="type == 1 ? qk : dd" alt="" class="img" />
    <div class="content">
      {{ type == 1 ? "暂无期刊" : "暂无订单" }}
    </div>
  </div>
</template>
  
<script>
import qk from "/src/assets/img/pic13.png";
import dd from "/src/assets/img/pic14.png";
export default {
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      qk,
      dd,
    };
  },
};
</script>
  
<style lang='scss' scoped>
.default {
  justify-content: center;
  align-items: center;
  .img {
    width: 195px;
    height: 98px;
  }

  .content {
    font-family: PingFang SC Bold;
    font-size: 14px;
    color: #333333;
  }
}
</style>