import { delToken, getToken, setToken, setUserInfo, delUserInfo } from '@/utils/storage'
import tool from '@/utils/tool';

export default {
  namespaced: true,
  state () {
    return {
      token: getToken('T_TOKEN') || '',
      userInfo:  tool.data.get('T_USER_INFO'),
    }
  },
  mutations: {
    setUserToken (state, payload) {
      state.token = payload
      setToken('T_TOKEN', payload)
    },
    logout (state) {
      state.token = ''
      state.userInfo = null
      delToken('T_TOKEN')
      delUserInfo('T_USER_INFO')
    },
    setUserInfo (state, payload) {
      state.userInfo = payload
      setUserInfo('T_USER_INFO', payload)
    },
  },
  actions: {},
  getters: {}
}
