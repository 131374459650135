<template>
  <div>
    <Button :color="bgcColor" @click="$emit('click')">
      <slot>
        <div :style="styleObj" class="flex-center">{{ text }}</div>
      </slot>
    </Button>
  </div>
</template>

<script>
import { Button } from "vant";
export default {
  props: {
    text: {
      type: String,
      default: "确定",
    },
    bgcColor: {
      type: String,
      default: "#1FB3FE",
    },
    styleObj: {
      type: Object,
      default: () => {
        return {
          color: "#fff",
          fontSize: "16px",
        };
      },
    },
  },
  components: {
    Button,
  },
};
</script>

<style lang='scss' scoped>
:deep .van-button--normal {
  padding: 0;
  border-radius: 129px;
}
</style>