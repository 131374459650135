<template>
  <div>
    <myPopup :show="show" @close="close" :title="title">
      <Form class="pop-from">
        <Field
          v-model="userInfo.name"
          name="学生姓名"
          label="学生姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '' }]"
        >
        </Field>
        <Field
          v-if="!schoolId"
          v-model="chooseAreaName"
          name="省市区"
          label="省市区"
          readonly
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="pickOpen(5)"
        >
        </Field>
        <Field
          v-model="userInfo.school"
          name="所在学校"
          label="所在学校"
          readonly
          placeholder="请选择学校"
          :rules="[{ required: true, message: '' }]"
          @click="pickOpen(1)"
        >
        </Field>
        <Field
          v-model="userInfo.school_year"
          name="入学年份"
          label="入学年份"
          readonly
          placeholder="请选择入学年份"
          :rules="[{ required: true, message: '' }]"
          @click="pickOpen(4)"
        >
        </Field>
        <Field
          class="my-field"
          v-model="userInfo.grade"
          name="所在年级"
          label="所在年级"
          readonly
          placeholder="请选择当前就读年级"
          :rules="[{ required: true, message: '' }]"
          @click="pickOpen(2)"
        >
        </Field>
        <Field
          class="my-field"
          v-model="userInfo.class"
          name="所在班级"
          label="所在班级"
          readonly
          placeholder="请选择当前就读班级"
          :rules="[{ required: true, message: '' }]"
          @click="pickOpen(3)"
        >
        </Field>
      </Form>
      <div class="flex-center">
        <myButton
          text="确认"
          :styleObj="{
            color: '#fff',
            fontSize: '16px',
            width: '311px',
            height: '48px',
          }"
          @click="submit"
        ></myButton>
      </div>
    </myPopup>
    <Popup v-model="pickShow" position="bottom" :style="{ height: '40%' }">
      <Picker
        ref="picker"
        :title="pickTitle"
        show-toolbar
        :value-key="pickValue"
        :columns="pickColumns"
        @confirm="pickConfirm"
        @cancel="pickCancel"
      />
    </Popup>
  </div>
</template>

<script>
import { Form, Field, Picker, Popup } from "vant";
import myPopup from "@/components/myPopup.vue";
import myButton from "@/components/myButton.vue";
import { getAllGrade, getAllClass, getAllSchool, getSchoolDetail, getAreaTree } from "@/api/api.js";
export default {
  props: {
    title: {
      type: String,
      default: "请完善学生信息",
    },
    show: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
    year: {
      type: String,
      default: "",
    },
    schoolId: {
      type: String,
      default: "",
    },
    schools: {
      type: String,
      default: "",
    },
    isOrderPage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Form,
    Field,
    Picker,
    Popup,
    myPopup,
    myButton,
  },
  data() {
    return {
      pickColumns: [],
      userInfo: {
        name: "",
        school: "",
        grade: "",
        class: "",
        school_year: ""
      },
      userForm: {
         name: "",
         school_id: "",
         grade_id: "",
         class_id: "",
         school_year: ""
      },
      chooseAreaName: '',
      chooseAreaIds: [],
      yearList: [],
      classList: [],
      gradeList: [],
      schoolList: [],
      pickTitle: "",
      pickValue: "name",
      pickType: null,
      pickShow: false,
      areaList: [],
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.getArea()
        this.initUserInfo()
        this.getAllGrade()
        this.getAllClass()
      }
    },
  },
  mounted(){
    this.getYearList()
    if (this.show) {
      this.getArea()
      this.getAllGrade()
      this.getAllClass()
    }
    this.initUserInfo()
  },
  methods: {
    initUserInfo() {
        if (this.form) {
            this.userInfo.name = this.form.name
            this.userInfo.school = this.form.school ? this.form.school.name : ''
            this.userInfo.school_year = this.form.school_year
            this.userInfo.grade = this.form.grade ? this.form.grade.name : ''
            this.userInfo.class = this.form.class ? this.form.class.name : ''
            this.userForm.name = this.form.name
            this.userForm.school_id = this.form.school ? this.form.school.id : ''
            this.userForm.school_year = this.form.school_year
            this.userForm.grade_id = this.form.grade ? this.form.grade.id : ''
            this.userForm.class_id = this.form.class ? this.form.class.id : ''

            if (this.schoolId) {
                this.userForm.school_id = this.schoolId
                this.getAllSchool()
            }else{
              if (this.form.school) {
                this.chooseAreaName = this.form.school.province.desc + this.form.school.city.desc + this.form.school.area.desc
                this.chooseAreaIds = [this.form.school.province.val, this.form.school.city.val, this.form.school.area.val]
                this.getAllSchool()
              }
            }
        }
    },
    getYearList() {
        let yearList = []
        for (let i = 2000; i <= 2050; i++) {
            yearList.push({
                name: i + '年',
                id: i
            })
        }
        this.yearList = yearList
    },
    getSchoolInfo() {

    },
    // 获取地区树
    getArea() {
      getAreaTree().then((res) => {
        this.areaList = res.data;
      });
    },
    getAllGrade() {
      getAllGrade().then((res) => {
        this.gradeList = res.data;
      });
    },
    getAllClass() {
      getAllClass().then((res) => {
        this.classList = res.data;
      });
    },
    getAllSchool() {
      let params = {}
      if (this.year) {
         params.buy_type = this.year == 1 ? 2 : this.year == 2 ? 1 : 0
      }
      if (this.schoolId) {
         params.id = this.schoolId
      }
      if (this.schools) {
         params.schools = this.schools
      }
      if (this.chooseAreaIds.length > 0) {
         params.province_id = this.chooseAreaIds[0]
         params.city_id = this.chooseAreaIds[1]
         params.area_id = this.chooseAreaIds[2]
      }
      getAllSchool(params).then((res) => {
        this.schoolList = res.data;
        if (this.schoolId && res.data.length > 0) {
            this.userInfo.school = res.data[0].name
        }
      });
    },
    pickConfirm(row) {
      console.log('pickConfirm', this.pickType, row)
      if (this.pickType == 1) {
        this.userForm.school_id = row.id;
        this.userInfo.school = row.name;
      } else if (this.pickType == 2) {
        this.userForm.grade_id = row.id;
        this.userInfo.grade = row.name;
      } else if (this.pickType == 4) {
        this.userForm.school_year = row.id;
        this.userInfo.school_year = row.name;
      } else if (this.pickType == 5) {
        let chooseArea = this.$refs.picker.getValues()
        let chooseAreaName = ''
        let chooseAreaIds = []
        chooseArea.forEach(item => {
          chooseAreaName += item.label
          chooseAreaIds.push(item.value)
        })
        this.chooseAreaName = chooseAreaName
        this.chooseAreaIds = chooseAreaIds
        this.getAllSchool()
      } else {
        this.userForm.class_id = row.id;
        this.userInfo.class = row.name;
      }
      this.pickShow = false
    },
    pickCancel() {
      this.pickShow = false
    },
    pickOpen(type) {
      this.pickType = type;
      this.pickValue = "name"
      if (type == 4) {
        this.pickTitle = "入学年份";
        this.pickColumns = this.yearList;
      }else if (type == 5) {
        this.pickTitle = "省市区";
        this.pickValue = "label"
        this.pickColumns = this.areaList;
      }else {
        this.pickTitle =
        type == 1 ? "所在学校" : type == 2 ? "所在年级" : "所在班级";
        this.pickColumns = type == 1 ? this.schoolList : type == 2 ? this.gradeList : this.classList;
      }
      this.pickShow = true;
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.userForm.name = this.userInfo.name
      if (this.userForm.name == "") {
          this.$toast("请输入姓名");
          return false;
      }
      if (!this.userForm.school_id) {
          this.$toast("请选择所在学校");
          return false;
      }
      if (!this.userForm.school_year) {
          this.$toast("请选择入学年份");
          return false;
      }
      if (!this.userForm.grade_id) {
          this.$toast("请选择所在年级");
          return false;
      }
      if (!this.userForm.class_id) {
          this.$toast("请选择所在班级");
          return false;
      }
      this.$emit("submit", this.userForm);
    },
  },
};
</script>

<style lang='scss' scoped>
:deep .pop-from {
  margin-bottom: 34px;
  .van-field__control {
    text-align: right;
  }
}
.my-field ::v-deep .van-cell__value .van-field__body .van-field__control::placeholder {
  color: #cc0000;
}
</style>
