<template>
  <div class="home flex-column flex1">
    <myNavBar title="科技小记者" placeholder />
    <div class="nav flex-column flex1">
      <div class="nav-top-box">
        <div class="nav-top">
          <div class="nav-top-name">{{ userForm ? userForm.name : '请完善资料' }}</div>
          <div class="nav-top-class">{{ userForm && userForm.school ? userForm.school.name : ''}} {{ userForm && userForm.grade ? userForm.grade.name : '' }} {{ userForm && userForm.class ? userForm.class.name : '' }}</div>
          <div class="nav-top-class" style="color: #FF194F;" v-if="year && userForm  && userForm.school && userForm.school.buy_type != 3">提示：本期刊{{ userForm.school.buy_type == 1 ? '半年' : userForm.school.buy_type == 2 ? '全年' : '半年和全年' }}起订</div>
          <div class="flex-center nav-top-tx">
            <div :class="zkFlag ? '' : 'u-line-1'">
              <img src="@/assets/img/pic5.png" alt="" class="tx-icon" />
              <span> 发货提示：</span>{{ shipmentTips }}
            </div>
            <img src="@/assets/img/pic12.png" alt="" class="zk" @click="zkFlag = !zkFlag">
          </div>
          <div class="flex-center edit-btn" @click="show = true">
            <img src="@/assets/img/pic6.png" alt="" class="edit-icon" />
            <div>修改</div>
          </div>
        </div>
      </div>
      <template v-if="productList.length > 0">
          <div class="nav-list flex1 no-scroll" style="overflow-y: scroll;">
            <div
              class="flex list-item"
              v-for="(item, index) in productList"
              :key="index"
              @click="gotoinfo(item.id)"
            >
              <img :src="item.icon.url" alt="" class="item-img" />
              <div class="item-info">
                <div class="item-info-name">{{ item.name }}</div>
                <div class="item-info-jj u-line-2">
                  {{ item.abstract }}
                </div>
                <div class="item-info-price">
                  <span class="fh">￥</span> {{ item.price }} <span class="qi">起</span>
                  <button class="blue-button">点击订阅</button>
                </div>
              </div>
            </div>
          </div>
          <div class="fyq">
            <Pagination v-model="currentPage" :page-count="totalPage" mode="simple" @change="changePage" />
          </div>
      </template>
      <template v-else>
          <empty :type="1" />
      </template>
    </div>
    <img
      src="@/assets/img/pic7.png"
      alt=""
      class="my-dd"
      @click="gotoMyOrder"
    />
    <userForm
      v-if="show"
      :title="userFormTitle"
      :year="year"
      :show="show"
      :form="userForm"
      :schoolId="schoolId"
      :schools="schools"
      @close="show = false"
      @submit="updateUserInfo"
    />
  </div>
</template>

<script>
import myNavBar from "@/components/myNavBar.vue";
import userForm from "@/components/userForm.vue";
import empty from "@/components/empty.vue";
import { Pagination, Toast, Dialog} from "vant";
import { productList, getConfig, getUser, editUser, getSchoolDetail } from "@/api/api.js";
export default {
  components: {
    myNavBar,
    userForm,
    Pagination,
    empty
  },
  data() {
    return {
      userFormTitle: '修改个人信息',
      loading: null,
      show: false,
      productList: [],
      zkFlag:false,
      changeSchool: 0,
      search: {
        page: 1
      },
      userForm: {},
      totalPage: 0,
      currentPage: 1,
      shipmentTips: '',
      schoolId: '',
      schools: '',
      year: '',
    };
  },
  created() {
     this.loading = this.$store.commit
  },
  mounted() {
    this.year = this.$route.query.year
    this.schoolId = this.$route.query.school
    this.schools = this.$route.query.schools
    this.initUser()
    this.getConfig()
  },
  methods: {
    async initUser() {
        this.userForm = await this.$Tool.data.get('T_USER_INFO')
        this.getProductList();
        setTimeout(() => {
          this.checkUserInfo();
        }, 600);
    },
    getConfig() {
      getConfig({key: ['shipment_tips']}).then((res) => {
        this.shipmentTips = res.data[0].value
      });
    },
    getProductList() {
      this.loading('showLoading')
      productList(this.search).then((res) => {
          this.loading('hideLoading')
          this.productList = res.data.list
          //总条数
          let total = res.data.total
          //每页条数
          let perPage = res.data.perPage
          //总页数
          this.totalPage = Math.ceil(total / perPage)
      }).finally(() => {
          this.loading('hideLoading')
      });
    },
    changePage(page){
      this.search.page = page
      this.getProductList()
    },
    // 修改个人信息
    updateUserInfo(row) {
        this.loading('showLoading')
        this.show = false
        editUser(row).then((res) => {
            this.loading('hideLoading')
            if (res.error == 0) {
              Toast.success('修改成功')
              if (this.schoolId && row.school_id == this.schoolId) {
                  this.schoolId = ''
                  this.$router.replace({path: '/', query: {year: this.year}})
              }
              this.getUserInfo()
            }
        }).finally(() => {
            this.loading('hideLoading')
        });
    },
    getUserInfo() {
      getUser().then(res => {
          if (res.error == 0) {
              this.userForm = res.data
              this.getProductList();
              this.$store.commit('user/setUserInfo', res.data)
          }
      })
    },
    // 获取学校详情
    getSchool(id) {
       getSchoolDetail({ id: id }).then(res => {
          if (res.error == 0) {
              if (!this.userForm.school) {
                  this.userForm.school = {
                      id: res.data.id,
                      name: res.data.name
                  }
              }
          }
       })
    },
    gotoinfo(id) {
      if (!this.checkUserInfo()) return false
      let query = {id: id}
      if (this.year) {
         query.year = this.year
      }
      this.$router.push({path: '/journalDetails', query: query})
    },
    gotoMyOrder() {
      if (!this.checkUserInfo()) return false
      let query = {}
      if (this.year) {
         query.year = this.year
      }
      this.$router.push({
        path: "/myOrder",
        query: query
      });
    },
    checkUserInfo() {
      this.userFormTitle = '修改个人信息'
      if (this.schoolId) {
         if (this.userForm.school && this.userForm.school.id == this.schoolId) {
             this.schoolId = ''
             this.$router.replace({path: '/', query: {year: this.year}})
             return false
         }
         if (this.userForm.school && !this.changeSchool && this.userForm.school.id != this.schoolId) {
            this.showSchoolPop()
            return false
         }
      }
      if (!this.userForm || !this.userForm.school || !this.userForm.grade || !this.userForm.class || !this.userForm.school_year) {
          this.show = true
          this.userFormTitle = '完善个人信息'
          return false
      }
      return true
    },
    showSchoolPop() {
        Dialog.confirm({
          title: '提示',
          message: '您已经选择了学校，是否重新选择？',
        }).then(() => {
          this.show = true
          this.changeSchool = 1
          this.userFormTitle = '完善个人信息'
        }).catch(() => {
          // on cancel
          this.changeSchool = 2
          this.schoolId = ''
          this.$router.replace({path: '/', query: {year: this.year}})
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.blue-button {
  position: relative;
  top: -5px;
  font-size: 1.4rem;
  margin-left: 80px;
  background-color: #1fb3fe;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.nav {
  padding: 16px;
}
.nav-top-box {
  background: #f6f6f6;
  border-radius: 10px 30px 10px 10px;
  overflow: hidden;
}
.nav-top {
  background: url("@/assets/img/pic4.png") repeat;
  width: 100%;
  // height: 120px;
  position: relative;
  padding: 10px 16px;
  box-sizing: border-box;
  .nav-top-name {
    font-family: PingFang SC Bold;
    font-size: 20px;
    font-weight: 700;
    color: #181818;
    margin-bottom: 16px;
  }

  .nav-top-class {
    font-family: PingFang SC Bold;
    font-size: 15px;
    font-weight: 700;
    color: #181818;
    margin-bottom: 16px;
  }
  .nav-top-tx {
    font-family: PingFang SC Medium;
    font-size: 13px;
    justify-content: flex-start;
    position: relative;
    span {
      color: #1fb3fe;
    }
    color: #666666;

    .zk {
      position: absolute;
      right: 0;
      bottom: 4px;
    }
  }
  .tx-icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
}

.edit-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 64px;
  height: 30px;
  border-radius: 5px;
  background: #1fb3fe;
  color: #fff;
  .edit-icon {
    width: 12.2px;
    height: 13px;
    margin-right: 5.4px;
  }
}

:deep .pop-from {
  margin-bottom: 34px;
  .van-field__control {
    text-align: right;
  }
}

.nav-list {
  margin-top: 16px;
}
.list-item {
  margin-bottom: 24px;
  .item-img {
    width: 98px;
    height: 134px;
    margin-right: 16px;
  }
  .item-info {
    .item-info-name {
      font-family: PingFang SC Bold;
      font-size: 17px;
      font-weight: 700;
      line-height: 23.8px;
      letter-spacing: 0px;
      margin-bottom: 12px;
      color: #333333;
    }
    .item-info-jj {
      font-family: PingFang SC Medium;
      font-size: 14px;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 0px;
      margin-bottom: 12px;
      color: #666666;
    }
    .item-info-price {
      color: #ff194f;
      font-family: DIN-Medium;
      font-weight: 400;
      font-size: 26px;
      .fh {
        font-family: DIN-Medium;
        font-weight: 400;
        font-size: 18px;
      }
      .qi {
        font-family: PingFang SC Medium;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
      }
    }
  }
}

.my-dd {
  position: fixed;
  bottom: 60px;
  right: 16px;
}
</style>
