import axios from 'axios'
import store from '@/store'
import router from "@/router";
import {Toast} from 'vant';
import {getUrlParam} from '@/utils/index.js'


let baseURL = ''

if (process.env.NODE_ENV == "development") {
    //开发环境
    baseURL = 'user/'
} else if (process.env.NODE_ENV == "production") {
    //生产环境
    baseURL = '/api/user'
} else {
    baseURL = '/api/user'
}

// 创建一个axios实例
const request = axios.create({
    baseURL,
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    const token = store.state.user.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
}, error => {
    return Promise.reject(error)
});

let isshow = true

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(response => {

        let year = getUrlParam('year')
        let school = getUrlParam('school')
        let schools = getUrlParam('schools')

        if (response.data.error !== 0) {
            if (response.data.error == 404) {
                Toast.fail('请求不存在')
            } else if (response.data.error == 500) {
                Toast.fail(response.data.message || '网络异常，请稍后再试')
            } else if (response.data.error == 401) {
                if (isshow) {
                    // Toast.fail('请登录')
                    store.commit('user/logout')
                    let path = '/login'

                    let params = []
                    if (year) {
                        params.push("year=" + year)
                    } else if (school) {
                        params.push("school=" + school)
                    } else if (schools) {
                        params.push("schools=" + schools)
                    }
                    if (params.length > 0) {
                        path += '?' + params.join('&')
                    }
                    router.push(path)
                }
                isshow = false
            } else if (response.data.error == 402) {
                if (isshow) {
                    Toast.fail('账号已被禁用')
                    store.commit('user/logout')
                    let path = '/login'
                    let params = []
                    if (year) {
                        params.push("year=" + year)
                    } else if (school) {
                        params.push("school=" + school)
                    } else if (schools) {
                        params.push("schools=" + schools)
                    }
                    if (params.length > 0) {
                        path += '?' + params.join('&')
                    }
                    router.push(path)
                }
                isshow = false
            } else if (response.data.error == 409) {

            } else {
                Toast.fail(response.data.message)
            }
        }


        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }

        return res;
    },
    error => {
        // 对响应错误做点什么
        if (error.response.status == 500 || error.response.status == 502) {
            Toast.fail('网络异常，请稍后再试')
        }
        return Promise.reject(error)
    }
)


export default request


